// https://getbootstrap.com/docs/4.1/getting-started/theming/

$theme-colors: (
  "primary": #4e4e4e,
  "secondary": #a3a3a3,
  "tertiary": #707070,
  "light": #ffffff,
  "dark": #000000,
  "olive": #bbbb9e,
);

// Documenta-sans-regular and Documenta-bold are fonts for numbers only
$font-family-sans-serif: "Documenta-sans-regular", "DTL Documenta Sans",
  sans-serif;
$font-family-serif: "Documenta-bold", "DTL Documenta", serif;

@font-face {
  font-family: "DTL Documenta Sans";
  src: url("/assets/fonts/DTLDocumentaSansTOT-Regular.otf");
}

@font-face {
  font-family: "DTL Documenta Sans";
  src: url("/assets/fonts/DTLDocumentaSansTOT-Italic.otf");
  font-style: italic;
}

@font-face {
  font-family: "DTL Documenta Sans";
  src: url("/assets/fonts/DTLDocumentaSansTOT-Italic.otf");
  font-style: oblique;
}

@font-face {
  font-family: "DTL Documenta";
  src: url("/assets/fonts/DTLDocumentaTOT-Regular.otf");
}

@font-face {
  font-family: "DTL Documenta";
  src: url("/assets/fonts/DTLDocumentaTOT-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "DTL Documenta";
  src: url("/assets/fonts/DTLDocumentaTOT-Italic.otf");
  font-style: italic;
}

@font-face {
  font-family: "DTL Documenta";
  src: url("/assets/fonts/DTLDocumentaTOT-Italic.otf");
  font-style: oblique;
}

@font-face {
  font-family: "Documenta-bold";
  src: url("/assets/fonts/Documenta-bold.ttf");
  unicode-range: U+30-39; // 0 to 9
}

@font-face {
  font-family: "Documenta-sans-regular";
  src: url("/assets/fonts/Documenta-sans-regular.ttf");
  unicode-range: U+30-39;
}

$navbarHeight: 3.625rem;
$navbarFooterHeight: 4.25rem;
$navbarWidth: 63.875rem; //974px + 2*1rem + 2*0.5rem padding
$shadow-dimensions: 3rem 3rem;
$content-view-quiz-margin-top: 8rem;

$fa-font-path: "/node_modules/@fortawesome/fontawesome-free/webfonts";

@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "bootstrap/scss/bootstrap.scss";

$desktop-min-width: map-get($grid-breakpoints, "lg");
$mobile-max-width: $desktop-min-width - 1;

$max-content-width: 57.875rem;

@function vh($quantity) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}

@mixin hyphen($value) {
  -moz-hyphens: $value;
  -o-hyphens: $value;
  -webkit-hyphens: $value;
  -ms-hyphens: $value;
  hyphens: $value;
}

html {
  // fix the hopping scrollbar
  overflow-y: scroll;

  @include hyphen(auto);
}

body {
  background-color: theme-color("primary");
  color: theme-color("light");
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;

  @media (min-width: $desktop-min-width) {
    font-size: 1.5rem;
  }

  min-height: vh(100);
}

img {
  height: auto;
  max-width: 100%;
}

.hide-desktop {
  @media (min-width: $desktop-min-width) {
    display: none !important;
  }
}

.hide-mobile {
  @media (max-width: $mobile-max-width) {
    display: none !important;
  }
}

.intro-video {
  height: vh(100);
  width: 100%;
  position: fixed;
  inset: 0;
}

.stele-nav {
  font-size: 1.125rem;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1030;
  height: 4.25rem;
  margin-block-end: 0;
  box-shadow: 0 4rem $shadow-dimensions theme-color("dark");
  background-color: theme-color("primary");

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  li {
    list-style: none;

    margin: 0 2rem 0 2rem;

    @media (min-width: $desktop-min-width) {
      margin: 0 8rem 0 8rem;
    }
  }

  li:first-child {
    margin-right: auto;
    visibility: hidden;
  }

  li:last-child {
    margin-left: auto;
    margin-right: 2.5rem;
  }

  .nav-link,
  .btn {
    padding: 0.25rem 0.5rem;
    color: theme-color("light");
  }

  svg {
    width: 1.75rem;
    margin-bottom: 0.1875rem;
  }

  svg polygon {
    fill: theme-color("tertiary");
  }

  .nav-link {
    color: theme-color("secondary");
  }

  .active .nav-link {
    color: theme-color("light");
  }
}

.navbar {
  flex-shrink: 0;

  img {
    max-height: 2.25rem;
  }

  li {
    list-style: none;
  }

  &.footer {
    li:first-child {
      padding-left: 1.5rem;
    }

    li:last-child {
      padding-right: 1.5rem;
    }
  }

  &.header {
    svg polygon {
      stroke: theme-color("light");
    }

    .active .nav-link {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        display: block;
        border-bottom: 1px solid theme-color("light");
        bottom: 0.3rem;
        left: 0.5rem;
        right: 0.5rem;
      }
    }
  }

  .nav-link,
  .btn {
    padding: 0.25rem 0.5rem;
    color: theme-color("light");

    svg {
      width: 1.75rem;
    }
  }

  &:not(.footer) {
    svg polygon {
      fill: theme-color("light");
    }
  }

  &:not(.footer) {
    @media(min-width: $navbarWidth) {
      margin-left: calc((100% - #{$navbarWidth}) / 2);
    }

    .btn {
      padding-top: 0;
      margin-right: 0.5625rem;
    }
  }

  &.footer {
    font-size: 0.8625rem;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1030;
    margin-block-end: 0;
    align-items: stretch;
    box-shadow: 0 4rem $shadow-dimensions theme-color("dark");

    svg {
      margin-bottom: 0.1875rem;

      path {
        stroke: theme-color("tertiary");
      }

      path,
      polygon {
        fill: theme-color("tertiary");
      }
    }

    .nav-item {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      font-size: 0.75rem;

      &.link-poi-list {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 1.75rem;
        }
      }

      .nav-link {
        padding: 0;

        svg {
          height: 1.75rem;
        }
      }
    }

    &:not(.stele) {
      @media (min-width: $desktop-min-width) {
        box-shadow: none;
        bottom: 0;
        left: auto;
        top: 0;
        height: auto !important;
        width: 6rem;
        flex-direction: column;
        justify-content: flex-start;
      }

      .nav-item {
        padding: 0 1rem 0 1rem;

        @media (min-width: $desktop-min-width) {
          flex: 0 0 auto;
          padding: 2rem 0;
          height: auto;
          font-size: 0.875rem;
        }

        &.burger-menu {
          display: none;
          padding-top: 0.1875rem;

          @media (min-width: $desktop-min-width) {
            display: flex;
            padding-top: 0.125rem;
          }

          path,
          polygon {
            stroke: theme-color("light");
            fill: theme-color("light");
          }
        }
      }
    }

    .active {
      .nav-link svg {

        path,
        polygon {
          stroke: theme-color("light");
          fill: theme-color("light");
        }
      }
    }
  }
}

.inverted {
  .fixed-top {
    background-color: theme-color("light");

    .nav-link {
      color: theme-color("primary");
    }

    .btn svg polygon {
      // Yes, this is another color than the nav-links. Because of the font size the nav link text looks
      // brighter, so we use a brighter color here.
      fill: theme-color("tertiary");
      stroke: theme-color("tertiary");
    }
  }
}

h1 {
  margin-bottom: 1rem;
  font-size: 1.75rem;
}

button {
  background-color: transparent;
  border: none;
  color: inherit;
  text-align: inherit;
  padding-left: 0;
  padding-right: 0;
  line-height: normal;

  &.navbar-toggler {
    // Without this flex, the sidebar close button would be 2.5px larger than the download close one.
    // I don't know the reason, it was figured out with try'n'error.
    display: flex;
    align-items: center;
  }
}

.btn {
  display: block;
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
}

.alert-container {
  position: fixed;
  left: 50%;
  top: 50%;

  .alert {
    transform: translate(-50%, -50%);
  }

  .alert-light {
    border-color: theme-color("secondary");
  }
}

.pointer {
  cursor: pointer;
}

.page {
  width: 100%;
  flex-grow: 1;
}

.sidebar-root~.page {
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
}

.sidebar-root {
  div {
    color: theme-color("primary");
  }

  a:active {
    color: theme-color("primary");
  }
}

.sidebar-close,
.sidebar-close-button,
.download-info-close,
.download-info-close-button {
  position: fixed;
  right: 1.5625rem;
  top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.stele {
    top: auto;
    right: auto;
    left: 1.5625rem;
    bottom: 0.75rem;
  }
}

.sidebar-close,
.sidebar-close-button {
  z-index: 1998;
}

.download-info-close,
.download-info-close-button {
  z-index: 2000;
}

.sidebar {
  text-transform: uppercase;
  padding: 1rem 0rem;
  position: fixed;
  width: 100%;
  background: theme-color("light");
  right: 0;
  top: 0;
  height: 100%;
  transition: all 0.3s;
  z-index: 1997;
  overflow-x: auto;
  display: flex;
  flex-direction: column;

  .sidebar-button :hover {
    color: #282828;
  }

  .sidebar-small-text {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  .sidebar-big-text {
    font-size: 2rem;
  }

  button {
    background-color: white;
    text-decoration: inherit;
    text-transform: inherit;
    color: inherit;
  }

  ul {
    list-style: none;
    padding-inline-start: 0;

    * {
      letter-spacing: 0.02125rem;
      color: theme-color("tertiary");
    }
  }

  .nav-link {
    padding: 0.75rem 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .nav-link-icon-label {
      padding-right: 0.75rem;
    }

    svg {
      width: 1rem;
    }
  }

  .data-protection-revocation {
    font-size: 0.625rem;
    text-transform: none;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 25.25rem;
    flex-shrink: 0;
    flex-grow: 0;
    color: theme-color("tertiary");

    margin-bottom: 4rem;

    button {
      padding: 0;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.sidebar-content {
  flex-grow: 1;
  flex-shrink: 0;

  @media screen and (orientation: portrait) {
    flex-direction: column;
  }

  @media screen and (orientation: landscape) {
    flex-direction: row;
  }
}

.download-info-root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1999;
  background: theme-color("light");
  color: theme-color("dark");
}

.download-info {
  height: 100%;
  max-width: $navbarWidth;
  margin-left: auto;
  margin-right: auto;
  padding: 3.5rem 1.5rem 2.375rem;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .download-info-box {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .download-info-header,
  .download-info-sub-header {
    letter-spacing: 0.02125rem;
  }

  .download-info-header {
    padding-bottom: 0.65rem;
  }

  @media (min-width: $desktop-min-width) {
    padding-bottom: 1.9375rem;
  }


  .download-info-sub-header {
    margin-top: 1.25rem;
    margin-bottom: 0.3125rem;

    @media (min-width: $desktop-min-width) {
      margin-bottom: 0.625rem;
    }
  }

  .download-info-list {
    display: flex;
    flex-direction: column;
  }

  .download-info-list-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    svg {
      width: 1.9375rem;
      flex-shrink: 0;
      padding-right: 0.75rem;
      padding-top: 0.3rem;

      fill: theme-color("tertiary");

      @media(min-width: $desktop-min-width) {
        padding-top: 0.6rem;
      }
    }
  }

  .download-info-store-buttons {
    svg {
      width: 14.125rem;
      margin-right: 1.4375rem;
      margin-top: 1.4375rem;
    }
  }

  .pwa-intro {
    margin-top: 2rem;

    @media (min-width: $desktop-min-width) {
      margin-top: 3.8125rem;
    }
  }
}

.download-info-content {
  overflow-y: auto;

  @media screen and (orientation: portrait) {
    flex-direction: column;
  }

  @media screen and (orientation: landscape) {
    flex-direction: row;
  }
}

.share-bar {
  padding: 1rem;
  position: fixed;
  width: 100%;
  background: theme-color("light");
  right: 0;
  bottom: 0;
  height: 20vh;
  transition: all 0.3s;
  z-index: 1999;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 4rem $shadow-dimensions theme-color("dark");
  color: #4e4e4e;

  .share-bar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.25rem;
    padding-bottom: 0.5rem;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2000;
      width: auto;
    }
  }

  input {
    padding-top: 0.3rem;
  }

  .share-bar-buttons {
    button {
      padding: 0;
      line-height: inherit;
    }
  }

  .share-bar-copy-box {
    margin-top: 0.7rem;
    padding-bottom: 0.5rem;
  }

  .share-icon {
    flex-basis: 2rem;
    flex-grow: 1;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      width: 2rem;
      margin-bottom: 0.4rem;
    }
  }
}

.navbar {
  .nav-item {
    font-size: 1.125rem;
  }

  &:not(.footer) {
    height: $navbarHeight;
  }

  &.footer {
    height: $navbarFooterHeight;
  }
}

.navbar-margin {
  margin-top: $navbarHeight;

  &.hidden {
    margin-top: 0;
  }
}

.static {
  background-color: theme-color("light");
  padding-top: 3.375rem;

  .static-page-container {
    max-width: $navbarWidth;
    flex-grow: 1;
    color: theme-color("dark");
    padding-bottom: 2rem;
  }

  h1 {
    color: theme-color("tertiary");
    font-size: 1.25rem;
    margin-bottom: 0.65rem;
    letter-spacing: 0.02125rem;
    text-transform: uppercase;
    line-height: 1.875rem;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0.035rem;
    text-transform: uppercase;
  }
}

.poi-list {
  display: flex;
  flex-direction: column;
  flex: 0 1 49rem;

  &.stele {
    padding: 4rem 0rem 4rem 0rem;
  }

  &::after {
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // If this is not set iOS-Safari doesn't draw the shadow
    border-bottom: solid 1px black;
    box-shadow: 0 3rem $shadow-dimensions theme-color("dark");
  }

  .item {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 50%;
    margin: 0 1rem -2rem 1rem;

    .list-image {
      mask-size: cover;
      background-blend-mode: multiply;

      img {
        visibility: hidden;
      }
    }

    .description {
      font-size: 1.25rem;
      transform: translate(80%, -3.5rem);
      padding: 0.5rem;
      hyphens: manual;

      @media (min-width: $desktop-min-width) {
        font-size: 1.5625rem;
      }

      .year {
        font-family: $font-family-serif;
        font-weight: bold;
        font-size: 1.6875rem;

        @media (min-width: $desktop-min-width) {
          font-size: 2.8125rem;
        }
      }

      .name {
        text-transform: uppercase;
        letter-spacing: 0.08rem;

        @media (min-width: $desktop-min-width) {
          letter-spacing: 0.028125rem;
          padding-bottom: 0.125rem;
        }


      }

      .address {
        font-size: 0.875rem;

        svg {
          height: 0.8125rem;
          margin-right: 0.25rem;

          polygon {
            fill: theme-color("light");
          }
        }
      }
    }

    &.odd {
      align-self: flex-end;

      .description {
        transform: translate(-80%, -3.5rem);
        text-align: right;
      }
    }
  }

  .external-places {
    font-size: 1rem;

    @media (min-width: $desktop-min-width) {
      font-size: 1.25rem;
    }

    .title {
      margin-top: 0.2rem;

      @media (min-width: $desktop-min-width) {
        margin-top: 0;
      }
    }

    .description {
      color: theme-color("secondary");
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

.poi {
  margin-top: -$navbarHeight;

  // Do not alter this class' height or linear-gradient without having and eye on .content-view-img-wrapper > img
  .top-gradient {
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    height: vh(20);
    z-index: 100;
    -webkit-mask-image: linear-gradient(180deg,
        #000 20%,
        rgba($black, 0.82) 40%,
        rgba($black, 0.69) 50%,
        rgba($black, 0.51) 68%,
        rgba($black, 0.3) 83%,
        transparent);
    mask-image: linear-gradient(180deg,
        #000 20%,
        rgba($black, 0.82) 40%,
        rgba($black, 0.69) 50%,
        rgba($black, 0.51) 68%,
        rgba($black, 0.3) 83%,
        transparent);
  }
}

.border-bottom {
  border-bottom: 1px solid #80bfca !important;
}

.content {
  color: theme-color("dark");
  margin-bottom: $navbarFooterHeight;

  &:not(.stele) {
    @media (min-width: $desktop-min-width) {
      margin-right: 6rem;
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: underline;
    color: theme-color("dark");
  }
}

.object-fit-contain {
  object-fit: contain !important;
}

.map-container {
  .find-me-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.625rem;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 1000;
    mix-blend-mode: difference;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fading-icon {
  -webkit-animation: fadeInOut 2s 5;
  -moz-animation: fadeInOut 2s 5;
  -o-animation: fadeInOut 2s 5;
  animation: fadeInOut 2s 5;
  animation-fill-mode: forwards;
}

#mapid {
  background-color: theme-color("primary");
  width: 100%;
  height: 100%;

  img {
    object-fit: contain;
  }
}

.map-marker-cluster {
  color: white;
  font-weight: bold;
  font-size: 0.8725rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-image: url("/assets/images/cluster-marker.png");
  padding-left: 1px;
  padding-bottom: 1px;
  font-family: "DTL Documenta Sans";
}

.leaflet-bar {
  box-shadow: none;

  &.leaflet-control {
    margin-right: 2.125rem;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-in:hover,
  .leaflet-control-zoom-out,
  .leaflet-control-zoom-out:hover,
  .leaflet-control-external-places,
  .leaflet-control-external-places:hover {
    color: transparent;
    border: none;
    background-size: contain;
    background-color: transparent;
    width: 1.75rem !important;
    height: 1.75rem !important;
  }

  .leaflet-control-zoom-in {
    background-image: url("/assets/images/plus.svg");
    margin-bottom: 1.25rem;
  }

  .leaflet-control-zoom-out {
    background-image: url("/assets/images/minus.svg");
    margin-bottom: 0.75rem;
  }

  .leaflet-control-external-places {
    background-image: url("/assets/images/external-places.svg");
  }
}

.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: none;
  border-radius: 0;
}

.leaflet-popup-content {
  h1 {
    margin-bottom: 0;
    font-family: $font-family-serif;
    font-size: 1.375rem;
    color: theme-color("tertiary");
  }

  .name {
    letter-spacing: 0.015625rem;
    word-wrap: break-word;
    margin-bottom: 0.625rem;
  }

  a {
    text-transform: none;
    color: theme-color("secondary");
  }

  color: theme-color("secondary");
  text-transform: uppercase;
  font-size: 0.75rem;
  margin: 1.125rem 1rem 0.84375rem 1.125rem;
}

.leaflet-container {
  font-family: inherit;

  a.leaflet-popup-close-button {
    right: 0.75rem;
    top: 0.75rem;
    width: 0.8125rem;
    height: 0.8125rem;
    color: transparent !important;
    background-image: url("/assets/images/close.svg");
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0;
  }
}

.leaflet-currentposition-pane {
  z-index: 605;
  pointer-events: none;
  mix-blend-mode: difference;

  img {
    max-width: none;
    max-height: none;
  }
}

.poi-details-container {
  background-color: white;
  min-height: calc(#{vh(100)} - #{$navbarHeight});
  padding-bottom: 1.1875rem;

  @media (min-width: $desktop-min-width) {
    min-height: vh(100);
  }

  .poi-details-content {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;

    .poi-details-title {
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 2rem;
      font-weight: normal;
      padding-left: 1.4rem;
      padding-right: 1.5rem;
    }

    .poi-details-element {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      @media (min-width: $desktop-min-width) {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
    }

    .poi-details-date {
      font-weight: bold;
      font-size: 1.125rem;
      margin-left: 1rem;
      margin-right: 1rem;
      position: absolute;

      &+div>div:first-child {
        text-indent: 2.5rem;
      }
    }
  }

  &>.content-view-img>.content-view-img-wrapper>img {
    max-height: calc(#{vh(80)} - #{$navbarHeight});
    object-fit: cover;
    // The top-gradients height is 20vh and the first 20% are completely covered. So we use 4vh here.
    // See https://gitlab.naymspace.de/amatik/demokratie-mapp/-/issues/81#note_405751
    object-position: center vh(4);
  }
}

.sidebar-root~.page {
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
}

.sidebar-root a:active {
  color: theme-color("primary");
}

.image-extra {
  &.closed {
    cursor: pointer;

    .fa {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      mix-blend-mode: difference;
    }
  }

  &.opened {
    background-color: theme-color("light");
    color: theme-color("dark");

    padding-top: 5rem;

    position: fixed;
    overflow: scroll;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;

    .close {
      cursor: pointer;
      position: fixed;
      top: 1rem;
      right: 2rem;
      z-index: 1051;

      svg {
        fill: theme-color("dark");
      }
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-content {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.audio-slideshow-container {
  background-color: theme-color("light");

  .audio-slideshow {
    height: calc(#{vh(100)} - #{$navbarFooterHeight});
    display: flex;
    flex-direction: column;

    @media (min-width: $desktop-min-width) {
      height: vh(100);
    }

    .image-gallery {
      flex-grow: 1;
      height: 1px;
      margin-top: 0;
      display: flex;
      justify-content: center;
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
      }

      svg {
        mix-blend-mode: difference;

        polygon {
          fill: white; //explicitly white for blend-mode
        }
      }

      .image-gallery-button-container {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;

        .image-gallery-button {
          display: flex;
          justify-content: start;
          align-items: center;
          height: 100%;
          width: 25%;
          cursor: pointer;

          &.button-left {
            justify-content: flex-start;
            padding-left: 0.5rem;

            @media (min-width: $desktop-min-width) {
              padding-left: 1rem;
            }
          }

          &.button-right {
            justify-content: flex-end;
            padding-right: 0.5rem;

            @media (min-width: $desktop-min-width) {
              padding-right: 1rem;
            }
          }
        }
      }
    }
  }

  .audio-slideshow-transcript {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1.1875rem;
    padding-top: 1.1875rem;
  }
}

.audio-player-wrapper {
  background-color: #2f2f2f;
  color: theme-color("light");
  padding: 0.5rem 1rem;

  .audio-player-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-playpause {
      margin: 0 1rem;
      width: 2rem;
      display: flex;
      justify-content: space-around;

      button {
        display: flex;
        align-items: center;
      }
    }

    &-center {
      font-size: 0.75rem;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      padding: 0 1rem;
      line-height: 2.5;
    }

    &-clock {
      align-self: flex-end;
      font-size: 0.625rem;
      margin-bottom: 0.25rem;
      font-family: "DTL Documenta Sans";
    }

    &-timeline {
      width: 100%;
    }

    &-title {
      line-height: 1.125rem;
      margin: 0;
      font-size: 0.75rem;
      font-weight: normal;
    }
  }
}

.comments {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  min-height: calc(#{vh(100)} - #{$navbarHeight});
  max-width: #{$navbarWidth - 4rem};
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $desktop-min-width) {
    min-height: vh(100);
  }

  .comments-headline {
    font-size: 1.875rem;
    margin-top: 4rem;
    color: white;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    letter-spacing: 0.0175rem;
    line-height: 2.8125rem;
    font-weight: normal;
    margin-bottom: 0;

    .caps {
      text-align: center;
      text-transform: uppercase;
    }
  }

  .add-comment-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5.3125rem;

    .btn {
      margin-top: 4rem
    }
  }

  .comment-list {
    font-size: 1.125rem;

    .comment-box {
      background-color: white;
      padding: 1.5rem;
      margin: 2rem 0;

      .single-comment-headline {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 1.1875rem;
        font-family: $font-family-serif;
        font-weight: bold;
        font-size: 0.9375rem;
        color: theme-color("tertiary");

        >*:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
}

.comment-success-flash {
  font-size: 1.3125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  text-align: center;

  &-headline {
    text-transform: uppercase;
    margin: 1rem 0;
  }

  svg {
    path {
      fill: theme-color("secondary");
    }
  }
}

.new-comment-form {
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  background-color: theme-color("primary");
  position: fixed;
  overflow: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;

  >* {
    max-width: #{$navbarWidth - 2.5rem};
  }

  .new-comment-headline {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;

    polygon {
      fill: white;
    }
  }

  input {
    width: 100%;
    margin-bottom: 0.5rem;
    border: none;
    padding: 0.5rem;
  }

  textarea {
    padding: 0.5rem;
    flex-grow: 1;
    width: 100%;
    height: 40%;
    resize: none;
  }

  .btn {
    margin-top: 2rem;
  }
}

.comments,
.new-comment-form {
  .btn {
    border-radius: 2rem;
    color: theme-color("light");
    background-color: theme-color("tertiary");
  }
}

.image-gallery-play-pause {
  position: absolute;
  right: 1rem;
  bottom: 1.0625rem;
  color: theme-color("light");
  background-color: theme-color("primary");
  padding: 0.25rem 0.75rem;
  border-radius: 2rem;
  font-size: 0.75rem;
  cursor: pointer;

  svg {
    margin-right: 0.25rem;
    margin-bottom: 0.125rem;
    height: 0.5625rem;

    rect,
    path {
      fill: theme-color("light");
    }
  }
}

.content-view-html {
  .content-view-img-subtexts {
    padding-top: 60px;
  }
}

.content-view-img {
  width: 100%;

  &-wrapper {
    position: relative;

    img {
      width: 100%;
    }
  }

  &-subtexts {
    margin: 0.5rem 0;
    font-size: 0.75rem;
  }
}

.content-view-quiz {
  display: flex;
  justify-content: center;
  min-height: calc(#{vh(100)} - #{$navbarHeight} - #{$navbarFooterHeight});
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $desktop-min-width) {
    min-height: calc(#{vh(100)} - #{$navbarHeight});
  }

  &.stele {
    flex-direction: column;
    align-items: center;
  }

  &.stele &-intro {
    padding-top: 1rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }

  &-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.hide-mobile {
      margin-top: $content-view-quiz-margin-top;
      margin-left: 2rem;
      justify-content: flex-start;
    }
  }

  &-buttons {
    max-width: $max-content-width;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &-question-container,
  &-summary {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: $content-view-quiz-margin-top;
    display: flex;
    flex-direction: column;
    background-color: theme-color("primary");
    color: theme-color("light");
  }

  &.stele &-question-container,
  &.stele &-summary {
    padding-left: 6rem;
    padding-right: 6rem;
    margin-top: 0;
    justify-content: center;
    max-width: 50rem;
    padding-bottom: 1rem;
  }

  &-next-question {
    align-self: flex-end;

    svg {
      height: 1.8125rem;
      margin-left: 0.5rem;

      polygon {
        fill: theme-color("light");
      }
    }
  }

  .play-again {
    align-self: flex-start;
    margin-left: -1rem;
  }

  &-summary {
    h1 {
      font-family: $font-family-serif;
      font-size: 1.25rem;
    }

    button.btn {
      text-transform: none;
    }

    .result-container {
      font-size: 1.125rem;
      width: 16rem;

      .result-bar {
        font-family: 'DTL Documenta Sans';
        margin-top: 1.125rem;
        border-radius: 2rem;
        background-color: theme-color("secondary");
        border-color: theme-color("secondary");
      }
    }

    .btn {
      width: 16rem;

      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      animation: pulse 2s;

      svg {
        margin-left: 0.75rem;
        height: 1.75rem;
        width: 1.75rem;

        path,
        polygon {
          stroke: theme-color("light");
          fill: theme-color("light");
        }
      }
    }
  }

  &.stele &-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-question-counter {
    font-family: $font-family-serif;
    font-size: 1.25rem;
  }

  &-question {
    margin-top: 0.5rem;
    line-height: 1.2;
  }

  .btn {
    margin-top: 1.125rem;
    color: theme-color("light");
    border-radius: 2rem;
  }

  &-answer-container {
    width: 100%;
  }

  &-answer {
    display: flex;

    &-annotation.btn {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-right: 1rem;
      align-self: center;
    }

    &-annotation.selected {
      padding-left: 0.25rem;
      margin-right: 3.75rem;
    }

    &-answer.btn {
      width: 100%;
      padding-left: 1.25rem;
      display: flex;
      align-items: center;

      @media (min-width: $desktop-min-width) {
        max-width: 31rem;
      }
    }

    &-icon {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .btn {
      background-color: theme-color("secondary");
      border-color: theme-color("secondary");
      text-align: left;
    }

    .btn.selected {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: theme-color("tertiary");
      border-color: theme-color("tertiary");

      @media (min-width: $desktop-min-width) {
        max-width: 35rem;
      }
    }
  }

  .content-view-img-wrapper {
    text-align: center;

    img {
      max-width: 30.875rem;
    }
  }

  svg path {
    stroke: white;
  }
}

.content-view-link-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .btn {
    border-radius: 2rem;
  }
}

.content-view-instagram-embed {

  iframe,
  blockquote {
    max-width: none !important;
  }
}

.intro {
  .intro-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow-x: auto;

    &.background {
      height: vh(100);
      width: 100vw;
    }
  }

  .intro-icon {
    margin-bottom: 5.6875rem;
  }

  .intro-overlay-content {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2002;
    width: calc(100% - 2rem);
    display: flex;
    font-weight: bold;
    font-family: $font-family-serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.43;

    .title {
      font-size: 2.1875rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include hyphen(none);
      align-self: center;

      .subtitle {
        font-size: 0.875rem;
        font-weight: normal;
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        margin-top: 0.75rem;
        letter-spacing: 0.0525rem;
      }
    }

    .introtext {
      margin-left: auto;
      margin-right: auto;
      max-width: 50rem;
      display: flex;
      flex-direction: column;
      font-size: 1.25rem;
      overflow-x: auto;
      padding-top: 2rem;
      padding-bottom: 2rem;

      &:after {
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        // If this is not set iOS-Safari doesn't draw the shadow
        border-bottom: solid 1px black;
        box-shadow: 0 3rem $shadow-dimensions theme-color("dark");

        @media (min-width: $desktop-min-width) {
          display: none;
        }
      }




      .download-notice {
        font-size: 0.875rem;
        color: theme-color("light");
        font-family: $font-family-sans-serif;
        line-height: 1.28;
        font-weight: normal;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;

        svg {
          height: 0.75rem;
          margin-left: 0.25rem;

          polygon {
            fill: theme-color("light");
          }
        }
      }
    }
  }

  .poi-list {
    height: calc(#{vh(100)} - #{$navbarHeight});
    overflow: hidden;

    .description {
      visibility: hidden;
    }
  }
}

.introtext-button,
.like-introtext-button {
  background-color: theme-color("olive");
  font-family: $font-family-sans-serif;
  align-self: center;
  border-radius: 2rem;
  color: theme-color("dark");
}

.introtext-button {
  margin-top: 2rem;

  @media (min-width: $desktop-min-width) {
    margin-top: 3.8125rem;
  }
}

.external-places-modal {
  p {
    text-align: center;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }

  a {
    text-decoration: underline;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.25rem;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .modal-background {
    padding: 1rem;
    background-color: theme-color("light");
    color: theme-color("primary");
    width: 17rem;
    display: flex;
    flex-direction: column;

    overflow-x: auto;
    margin-bottom: $navbarFooterHeight;
    margin-top: $navbarHeight;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    flex-grow: 2;
    border: none;
    flex-shrink: 0;
  }
}


.warning-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.25rem;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .modal-background {
    padding: 1rem;
    background-color: theme-color("light");
    color: theme-color("primary");
    width: 17rem;
    display: flex;
    flex-direction: column;

    overflow-x: auto;
    margin-bottom: $navbarFooterHeight;
    margin-top: $navbarHeight;
  }

  .modal-headline {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.6rem;
    padding-bottom: 1rem;
    flex-shrink: 0;
  }

  .modal-content {
    display: Flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    flex-grow: 2;
    border: none;
    flex-shrink: 0;

    .navigation-button {
      font-size: 0.75rem;
      padding: 1rem 0;
      margin: 1rem 0;
    }
  }

  .modal-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 1rem;
    flex-shrink: 0;

    .button {
      margin-top: 0.5rem;
      font-size: 1.25rem;
      border-radius: 2rem;
      padding: 0.75rem 1.5rem;
      color: theme-color("light");
      cursor: pointer;
    }
  }

  .caps {
    text-align: center;
    text-transform: uppercase;
  }
}

.data-protection-modal {
  z-index: 2003;

  .modal-background {
    overflow-y: hidden;
    padding: 1rem 0 0 0;

    .modal-headline,
    .modal-content .text {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .modal-headline,
  .modal-content {
    word-break: break-word;
  }

  .modal-headline {
    font-size: 1.25rem;
    text-align: center;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    overflow-y: hidden;

    .text {
      overflow-y: auto;
      font-size: 1.125rem;

      a {
        font-style: italic;
      }
    }

    .modal-button {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      flex-shrink: 0;
      box-shadow: 0 4rem $shadow-dimensions theme-color("dark");
      height: 5.5rem;
      padding-bottom: 0;
      align-items: center;

      button {
        background-color: theme-color("olive");
        color: theme-color("dark");
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 0;
        flex-basis: 1%;
        flex-grow: 1;
        padding: 0.5rem 1rem;
        max-width: 4.5rem;
        font-size: 1.125rem;
      }
    }
  }
}

.hidden {
  visibility: hidden !important;
}

.object-container {
  position: relative;

  .object-overlay {
    z-index: 1;
    position: absolute;
    left: 50%;
    bottom: 25%;
    transform: translate(-50%, 0);
    pointer-events: none;

    @media (min-width: $desktop-min-width) {
      bottom: 20%;
    }

    svg {
      width: 3.1875rem;
      height: 2.3125rem;

      path {
        fill: theme-color("secondary");
      }
    }
  }

  .object-frame {
    border: none;
    outline: none;

    width: 100%;
    height: calc(100vh - #{$navbarHeight} - #{$navbarFooterHeight});

    @media (min-width: $desktop-min-width) {
      height: calc(100vh - #{$navbarHeight});
    }
  }

  .reset-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;

    bottom: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    font-size: 1rem;

    @media (min-width: $desktop-min-width) {
      bottom: 8%;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1.125rem;
    }

    svg {
      margin-right: 1rem;
      width: 1.25rem;
    }

    .btn {
      border-radius: 2rem;
      width: max-content;

      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}

///// Audio player progress bar styling

input[type="range"] {
  width: 100%;
  margin: 2.5px 0;
  background-color: transparent;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  border: 0;
  border-radius: 5px;
  width: 100%;
  height: 3px;
  cursor: pointer;
  margin: 8px 0;
}

input[type="range"]::-webkit-slider-thumb {
  margin-top: -5px;
  width: 12px;
  height: 12px;
  background: theme-color("light");
  border-radius: 12px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type="range"]::-moz-range-track {
  border: 0;
  border-radius: 5px;
  width: 100%;
  height: 3px;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: theme-color("light");
  border-radius: 10px;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 3.5px 0;
  color: transparent;
  width: 100%;
  height: 5px;
  cursor: pointer;
}

input[type="range"]::-ms-fill-lower {
  border: 0;
}

input[type="range"]::-ms-fill-upper {
  background: theme-color("secondary");
  border: 0;
}

input[type="range"]::-ms-thumb {
  width: 10px;
  height: 10px;
  background: theme-color("light");
  border: 2px solid green; /////
  border-radius: 28px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}

@supports (-ms-ime-align: auto) {

  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}


body.using-mouse :focus {
  outline: none;
}

.sr-only:focus {
  width: auto;
  height: auto;
  clip: unset;
  z-index: 10000;
  position: fixed;
  outline: auto;
  background: theme-color("primary");
  color: theme-color("light");
}

:focus,
.btn:focus {
  outline: 1px dotted theme-color("primary");
  outline: 5px auto -webkit-focus-ring-color;
}

@keyframes iframe-white-flash-suppressor {
  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

// Safari browsers flash iframes white on loading. So we hide all iframes 1 second and hope the flashing is over then.
iframe {
  animation: 1s iframe-white-flash-suppressor;
  animation-fill-mode: forwards;
  visibility: hidden;
}

#__parcel__error__overlay__ {
  &>div {
    white-space: pre;
    overflow: scroll;
  }
}